<template>
  <div
    class="modal fade"
    id="staticBackdrop"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            Please choose a coverage type
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                  data-bs-dismiss="modal"
                >
                  Auto
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p>
                    We have every policy you'd need for any vehicle. Below is
                    just a few examples of the different types of vehicle
                    coverage we offer.
                  </p>
                  <div class="col px-0 mb-1 modalListText">• Car</div>
                  <div class="col px-0 mb-1 modalListText">• Rv</div>
                  <div class="col px-0 mb-1 modalListText">• Boat</div>
                  <div class="col px-0 mb-1 modalListText">• Motorcycle</div>
                  <div class="col px-0 modalListText">• Other</div>

                  <div class="ms-auto text-center my-1">
                    <span class="quoteHeaderButton px-3 py-2 bold pointer">
                    <router-link
                      :to="{ name: 'auto' }"
                      class="quoteText quoteHeaderButton px-3 py-2 bold pointer"
                    >
                      Get Started
                    </router-link>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                  data-bs-dismiss="modal"
                >
                  Commercial
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p>
                    Any coverage your business requires, we can help. Listed
                    below is just a few of the many policies we can cover for
                    your small or large business.
                  </p>
                  <div class="col px-0 mb-2 modalListText">
                    • General liability insurance
                  </div>
                  <div class="col px-0 mb-2 modalListText">
                    • Business owner's policy
                  </div>
                  <div class="col px-0 mb-2 modalListText">
                    • Professional liability insurance
                  </div>
                  <div class="col px-0 mb-2 modalListText">
                    • Workers' compensation insurance
                  </div>
                  <div class="col px-0 mb-2 modalListText">
                    • Commercial property insurance
                  </div>
                  <div class="col px-0 mb-2 modalListText">
                    • Cyber liability insurance
                  </div>
                  <div class="col px-0 modalListText">• Other</div>

                  <div class="ms-auto text-center my-1">
                    <router-link
                      :to="{ name: 'auto' }"
                      class="quoteText quoteHeaderButton px-3 py-2 bold pointer"
                    >
                      Get Started
                    </router-link>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Home
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p class="text-center">
                    Any home, any size, we've got you covered.
                  </p>

                  <div class="ms-auto text-center my-1">
                    <router-link
                      :to="{ name: 'auto' }"
                      class="quoteText quoteHeaderButton px-3 py-2 bold pointer"
                    >
                      Get Started
                    </router-link>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFour">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  Mobile home
                </button>
              </h2>
              <div
                id="collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p class="text-center">
                    Any home, any size, we've got you covered.
                  </p>

                  <div class="ms-auto text-center my-1">
                    <a
                      href="mobilehome.html"
                      class="quoteText quoteHeaderButton px-3 py-2 bold pointer"
                      >Get Started</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Modal",
};
</script>
