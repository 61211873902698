<template>
  <div id="app" class="wrapper m-0 p-0" style="overflow: hidden">
    <Modal/>
    <NavBar />
    <router-view />
    <Footer/>
  </div>
</template>
<script>
import Footer from './components/Footer.vue'
import NavBar from "./components/NavBar";
import Modal from './components/Modal'

export default {
  name: "Insurance",
  components: {
    NavBar,
    Footer,
    Modal
  },
};
</script>
