<template>
    <div class="nav-container mt-0 pt-0" v-if="$route.name !== 'auto'">
        <!-- Desktop Navbar -->
        <div class="d-none d-md-block">
            <b-row class="h5 my-0 py-3 text-black px-2">
                <b-col class="my-auto bold h3 genBlueDark">
                    <router-link to="/" class="navBrand pointer genBlueDark">Next Gen Insurance</router-link>
                </b-col>
                
                <b-col class="text-right">
                    <b-row class=" align-middle mt-1">
                        <b-col class="genBlueDark h5">
                            <router-link to="about" class="navBrand pointer genBlueDark mr-3">About Us</router-link>
                            <router-link to="contact" class="navBrand pointer genBlueDark">Contact</router-link>
                        </b-col>
                    </b-row>
                </b-col>
                
                <b-col class="mt-1 number my-auto quoteHeader text-right col-auto">
                    <span class="quoteHeaderButton px-3 py-2 bold pointer" @click="$router.push({ name: 'auto' })">
                        Get Auto Quote
                    </span>
                </b-col>
            </b-row>
        </div>
        
        <!-- Mobile Navbar -->
        <div class="d-md-none">
            <b-navbar toggleable="lg" type="light" variant="light" class="pr-4">
                <router-link to="/" class="navBrand pointer genBlueDark">Next Gen Insurance</router-link>

                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

                <b-collapse id="nav-collapse" is-nav>
                    <b-navbar-nav>
                        <router-link to="about" class="pointer">About Us</router-link>
                        <router-link to="contact" class="pointer">Contact</router-link>
                    </b-navbar-nav>
                    
                    <!-- Right aligned nav items -->
                    <hr/>

                    <b-navbar-nav class="ml-auto">
                       <span class="quoteHeaderButton px-3 py-2 bold pointer" @click="$router.push({ name: 'auto' })">
                        Get Auto Quote
                    </span>
                    </b-navbar-nav>
                </b-collapse>
            </b-navbar>
        </div>
    </div>
    
    <div class="nav-container mt-0 pt-0" v-else>
        <div class="d-none d-md-block">
            <b-row class="my-0 py-3 text-black h5 px-2" align-h="center">
                <b-col class="my-auto bold h3 genBlueDark">
                    <router-link to="/" class="navBrand pointer genBlueDark">Next Gen Insurance</router-link>
                </b-col>

                <b-col class="text-center text-lg-right my-auto" lg="6">
                    <b-col class="text-center text-lg-right px-0">
                        <span class="bold">Have questions? </span>
                        <span class="callQuoteHeader pointer fs18">(803) 791-0995</span>
                    </b-col>
                    <b-col class="px-0">
                        <p class="smallQuoteHeader pb-0 mb-0">9am - 5:00 pm EST | Monday - Friday</p>
                    </b-col>
                </b-col>
            </b-row>
        </div>
        <div class="d-md-none">
            <b-navbar toggleable="lg" type="light" variant="light" class="pr-4">
                <b-navbar-brand href="#">
                    <router-link to="/" class="navBrand pointer genBlueDark">Next Gen Insurance</router-link>
                </b-navbar-brand>

                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

                <b-collapse id="nav-collapse" is-nav>
                    <b-navbar-nav>
                        <router-link to="about" class="pointer">About Us</router-link>
                        <router-link to="contact" class="pointer">Contact</router-link>
                    </b-navbar-nav>
                </b-collapse>
            </b-navbar>
        </div>
    </div>
</template>
<script>
export default {
    name: "NavBar",
};
</script>
