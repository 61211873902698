import Vue from "vue";
import Vuex from "vuex";
import { driverInformation } from "@/data/loops";
import collect from "collect.js";
import createDriver from "@/support/createDriver";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        data: {
            application: {},
            drivers: [],
            vehicles: [],
        },
        driver: driverInformation,
    },

    getters: {
        data: (state) => state.data,
        drivers: (state) => state.data && state.data.drivers,
        vehicles: (state) => state.vehicles && state.data.vehicles,
        currentDriver: (state) => state.driver,
        getById: (state) => (id) => {
            return state.data.drivers.find((driver) => driver.id === id);
        },
    },

    mutations: {
        updateApplicationData(state, payload) {
            console.log(payload)
            state.data.application = Object.assign(state.data.application, payload);
        },

        addDriver(state) {
            const nextId = collect(state.data.drivers).max("id");
            state.driver = createDriver(null, null, null, null, nextId + 1);
        },
        editDriver(state, driver) {
            state.driver = driver;
        },
        findDriver(state, id) {
            return state.data.drivers.find((o) => o.id === id);
        },
        removeDriver(state, id) {
            var index = state.data.drivers.findIndex((o) => o.id == id);
            state.data.drivers.splice(index, 1);
            state.data.drivers.map((o) => o.id - 1);
        },
        stopEditing(state) {
            state.driver = createDriver(null, null, null, null, state.data.drivers.length);
        },
        updateDriverData(state, payload) {
            state.data.drivers = state.data.drivers.map((d) => {
                if (d.id === payload.id) {
                    if (d.id === "undefined" || payload.id === "undefined") {
                        payload.id = collect(state.data.drivers).max("id") + 1;
                    }
                    return Object.assign({}, d, payload);
                }
                return d;
            });
            return;
        },
        
        addVehicle(state, payload) {
            const findVehicle = state.data.vehicles.find((o) => o.id === payload.id);
            if (findVehicle) {
                return Object.assign({}, findVehicle, payload);
            }

            return state.data.vehicles.push(payload);
        },
        removeVehicle(state, id) {
            var index = state.data.vehicles.findIndex((o) => o.id == id);
            state.data.vehicles.splice(index, 1);
            state.data.vehicles.map((o) => o.id - 1);
        },

    },
});

export default store;
