import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [{
        path: "/",
        name: "landing",
        component: () =>
            import ( /* webChunkName: "landing" */ "../views/landing.vue"),
    },
    {
        path: "/auto",
        name: "auto",
        component: () =>
            import ( /* webpackChunkName: "auto2" */ "../views/auto.vue"),
    },
    {
        path: "/ref",
        name: "ref",
        component: () =>
            import ( /* webpackChunkName: "ref" */ "../views/validation-reference.vue"),
    },
    {
        path: "/about",
        name: "about",
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/about.vue"),
    },
    {
        path: "/contact",
        name: "contact",
        component: () =>
            import ( /* webpackChunkName: "contact" */ "../views/contact.vue"),
    },
    {
        path: "/privacy",
        name: "privacy",
        component: () =>
            import ( /* webpackChunkName: "contact" */ "../views/privacypolicy.vue"),
    },
    {
        path: "/terms",
        name: "terms",
        component: () =>
            import ( /* webpackChunkName: "contact" */ "../views/termsandconditions.vue"),
    },
    {
        path: "/disclaimer",
        name: "disclaimer",
        component: () =>
            import ( /* webpackChunkName: "contact" */ "../views/disclaimer.vue"),
    },
];

const router = new VueRouter({
    mode: "history",
    scrollBehavior,
    base: process.env.BASE_URL,
    routes,
});

function scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
        return savedPosition;
    }

    if (to.hash) {
        return { selector: to.hash };
    }

    const [component] = router.getMatchedComponents({...to }).slice(-1);

    if (component && component.scrollToTop === false) {
        return {};
    }

    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({ x: 0, y: 0 });
        }, 190);
    });
}

export default router;