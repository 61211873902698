/* eslint-disable no-undef */
import Vue from "vue";

// eslint-disable-next-line no-unused-vars
const dump = (value, prefix = null) => {
    if(value === undefined || value === null) {
        console.log(value)
        return;
    }
    const obj = JSON.parse(JSON.stringify(value));
    console.dir(obj);
    window.debugCalls++;
};

const plugin = {
    install() {
        Vue.prototype.$dd = dump;
        Vue.prototype.$dump = dump;
    },
};

export default plugin;