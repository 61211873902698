import Vue from "vue";
import axios from "axios";
import VueToast from "vue-toast-notification";
import VueAxios from "vue-axios";
import VueSmoothScroll from "vue2-smooth-scroll";
import VueDump from "./dump";
import VueCarousel from 'vue-carousel';
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueDump);

Vue.use(VueCarousel)

Vue.use(VueSmoothScroll);

Vue.use(VueAxios, axios);

Vue.use(VueToast, {
    duration: 3000,
    position: "top",
});
