import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store/index'
import '@/plugins/bootstrap-vue'
import '@/plugins/index';
import '@/assets/scss/layout.scss'

Vue.config.productionTip = false

const app = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

window.vm = app


Vue.component("v-style", {
  render: function (createElement) {
      return createElement("style", this.$slots.default);
  },
});
