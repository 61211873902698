<template>
  <footer class="footer">
        <!--Call now-->
    <div class="callWrapper">
        <div class="text-center">
            <p class="mb-0 py-4 callText fs20">
                <span class="bold">Call (803) 791-0995</span>
                9:00 a.m. - 5:00 p.m. EST, Monday - Friday
            </p>
            <div class="col-6 mx-auto">
                <div class="divider"></div>
            </div>
        </div>
    </div>
    <div class="footerWrapper">
      <div class="footerContainer py-3">
      
        <div class="col-12 col-md-auto text-center pb-3">
            <span class="termItem">© 2022 Next Gen Insurance. ALL RIGHTS RESERVED. (SC license #009682)</span>
        </div> 
      
        <div class="legalTerms pb-3">
          <div class="row termsList text-center justify-content-center">
          
            <div class="col-12 col-md-auto">
                <router-link to="privacy" target="_blank" class="pointer termItem">Privacy policy</router-link>
            </div>
            <div class="col-12 col-md-auto">|</div>
            
            <div class="col-12 col-md-auto">
                <router-link to="terms" target="_blank" class="pointer termItem">Terms & Conditions</router-link>
            </div>
            <div class="col-12 col-md-auto">|</div>
            
            <div class="col-12 col-md-auto">
                <router-link to="disclaimer" target="_blank" class="pointer termItem">Disclaimer</router-link>
            </div>
            <div class="col-12 col-md-auto">|</div>
            
            <div class="col-12 col-md-auto">
                <router-link to="contact" target="_blank" class="pointer termItem">Contact</router-link>
            </div>
            <div class="col-12 col-md-auto">|</div>
            
            <div class="col-12 col-md-auto">
                <router-link to="about" target="_blank" class="pointer termItem">About Us</router-link>
            </div>
          </div>
        </div>
        <div class="entity text-center col-12 col-md-7 mx-auto">
          <p>
            The information provided on this website does not constitute insurance advice. All content and materials are for general
            informational purposes only. Complete Next Gen's online application and contact one of our licensed insurance professionals to
            obtain advice for your specific business insurance needs.
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer",
};
</script>
