export const driverTableFields = [
    { key: "id", label: "#" },
    { key: "first_name", label: "First name" },
    { key: "last_name", label: "Last name" },
    { key: "dob", label: "DOB" },
    { key: "edit", label: "Edit" },
    { key: "remove", label: "Remove" },
];

export const driverInformation = {
    first_name: null,
    middle_name: null,
    last_name: null,
    dob: null,
    gender: null,
    marital_status: null,
    drivers_license_number: null,
    drivers_ssn: null,
    license_status: null,

    sr22: null,
    sr22_state_filed: null,
    sr22_reason: null,

    prior_insurance: null,
    prior_insurance_carrier: null,
    prior_insurance_expiration_date: null,

    foreign_licensed: null,
    foreign_driving_experience_years: null,
    foreign_driving_experience_months: null,

    residence_type: null,
    residence_status: null,

    valid: false,
};