export default (fname, mname, lname, dob, id=1) => {
    return {
        id,
        first_name: fname,
        middle_name: mname,
        last_name: lname,
        dob: dob,
        gender: null,
        marital_status: null,
        drivers_license_number: null,
        drivers_ssn: null,
        license_status: null,

        sr22: null,
        sr22_state_filed: null,
        sr22_reason: null,

        prior_insurance: null,
        prior_insurance_carrier: null,
        prior_insurance_expiration_date: null,

        foreign_licensed: null,
        foreign_driving_experience_years: null,
        foreign_driving_experience_months: null,

        residence_type: null,
        residence_status: null,

        valid: false,
    };
};
