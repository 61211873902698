import Vue from "vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

const settings = {
    breakpoints: [`xs`, "sm", "md", "lg", "xl"],
};

Vue.use(BootstrapVue, settings);
Vue.use(IconsPlugin);
